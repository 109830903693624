.main {
  background-color: #FFFFFF;

}

#root {
  /* background-image: url("bg.jpeg"); */
  background-size: cover;
  position: relative;
  min-height: 100vh;
}

.mf {
  /* background-image: url("bg.jpeg"); */
  margin: 0;
}

.main-form {
  background-color: #FFFFFF;
  padding-left: 2rem !important;
  padding-right: 2rem !important;
  margin-bottom: 3rem;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.form-validate {
  padding-top: 2rem;
}

.btn-main-form {
  background-color: #039D53 !important;
  border-color: #039D53 !important;


}

.content {
  margin: auto;
  background-color: #FFFFFF;
  margin-top: 1rem;
  padding: 3rem;

}

.nav-link {
  color: #039D53 !important;
}

.photo-control img {
  width: auto !important;
  height: 210px !important;
  padding-bottom: 2rem;
}

.rbt-close-content {
  display: none !important
}

/* .btn-margin{
  margin: 1rem 1rem 1rem
} */

#footer {
  position: absolute;
  /* bottom: 0; */
  width: 100%;
  height: 2.5rem;
  /* position: fixed; */
  bottom: 0;
  background-color: #FFFFFF;
}

.foop {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.remove-btn{
  position: absolute;
  top:0px;
  right:0px;
}


.cintillo {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #039D53;
  text-align: center;
  color: white;
  font-size: 1.2rem;
  height: 2rem;
  width: 100%;
}

.react-tel-input .form-control {
  display: block;
  width: 100% !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  line-height: 1.5;
  color: var(--bs-body-color) ;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  appearance: none;
  border-radius: var(--bs-border-radius);
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.form-control:disabled {
  background-color: var(--bs-secondary-bg) !important;
}