.overlay {
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .spinner {
    width: 4rem;
    height: 4rem;
  }
  