.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.foto_container {
  display: flex;
  justify-items: center;
  justify-content: center;
  height: 100%;
  align-items: center;
}

.foto_container img {
  border-radius: 5px;
  /* border: 1px solid rgba(0, 0, 0, 0.175); */
}

.foto_container img {
  width: auto !important;
  height: 210px !important;
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;
}

body {
  /* background-image: url("img/bg.jpeg"); */
  /* background-color: #f6f8fa !important; */
  margin: 0;
}

.card,
.card-light {
  border-radius: 5px;
  background-color: #ffffff;
  margin-bottom: 30px;
  -webkit-box-shadow: 2px 6px 15px 0px rgba(69, 65, 78, 0.1);
  -moz-box-shadow: 2px 6px 15px 0px rgba(69, 65, 78, 0.1);
  box-shadow: 2px 6px 15px 0px rgba(69, 65, 78, 0.1);
  border: 0px;
}

.form-control {
  border-color: #ccc !important;
}

.sweet-alert {
  background-color: transparent !important;
  box-shadow: none !important;
}

/* .uk-heading-line{
  position: relative;
}
.uk-heading-line span.line
{
  position: absolute;
  width: 100%;
  border-bottom: calc(.2px + .05em) solid #e5e5e5
} */

/* .photo_url_container{
  display: flex;
  justify-content: center;
  flex-direction: column;
} */

.photo_url_img {
  margin-bottom: 15px;
  box-shadow: 0 1px 15px 1px rgba(39, 39, 39, 0.1);
  margin-bottom: 14px;
}

